import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BUNDLE_ROUTE, FINANTIAL_CLOSURE_ROUTE, GIFT_ROUTE, GROUPS_ROUTE } from "src/core/routing";
import style from "./styles.module.css";
import individual from "../../../assets/osoba_white.svg";
import closure from "../../../assets/finantial_closure.svg";
import caret from "../../../assets/caret_right.png";
import giftIcon from "../../../assets/buttonIcons/ticket.png";
import cartIcon from "../../../assets/kosik.svg";
import groupIcon from "../../../assets/Path 519.png";
import { styles } from "./style";

export const TicketTypeContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isTablet, setIsTablet] = useState<boolean>(window.innerWidth <= 800);

  const updateMedia = () => {
    setIsTablet(window.innerWidth <= 800);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const handleNavigateToBundlesRoute = () => {
    navigate(BUNDLE_ROUTE);
  };

  const handleNavigateToGiftsRoute = () => {
    navigate(GIFT_ROUTE);
  };

  const handleNavigateToFinantialClosure = () => {
    navigate(FINANTIAL_CLOSURE_ROUTE);
  };

  const handleNavigateToGroups = () => {
    navigate(GROUPS_ROUTE);
  };
  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      !localStorage.getItem("isLoggedIn") ||
      !localStorage.getItem("name") ||
      !localStorage.getItem("email")
    ) {
      localStorage.clear();
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Box className={style.container}>
      <Typography
        textAlign={"center"}
        color={"#062762"}
        fontWeight={600}
        variant="h2"
        margin={"0 0 8vh 0"}
      >
        {t("choose_the_following_variant")}
      </Typography>
      <Button
        sx={{
          ...styles.button,
          textDecoration: "none !important",
          "&:hover": {
            backgroundColor: "#062762",
            color: "#FFFFFF !important",
            textDecoration: "none !important",
          },
        }}
        className={style.button}
        onClick={() => {
          handleNavigateToBundlesRoute();
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
      >
        <img src={individual} className={style.icon} />
        <Box>
          <span className={style.buttonText}>{t("tickets_for_individual")}</span>
        </Box>
        <img
          className={style.caret}
          src={caret}
          style={{
            position: "absolute",
            top: "50%",
            left: "100%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </Button>
      {isTablet && (
        <Button
          sx={{
            ...styles.button,
            textDecoration: "none !important",
            "&:hover": {
              backgroundColor: "#062762",
              color: "#FFFFFF !important",
              textDecoration: "none !important",
            },
          }}
          className={style.button}
          onClick={() => {
            handleNavigateToGroups();
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          <img src={groupIcon} className={style.icon} />
          <Box>
            <span className={style.buttonText}>{t("group_visit")}</span>
          </Box>
          <img
            className={style.caret}
            src={caret}
            style={{
              position: "absolute",
              top: "50%",
              left: "100%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </Button>
      )}
      {isTablet && (
        <Button
          sx={{
            ...styles.button,
            textDecoration: "none !important",
            "&:hover": {
              backgroundColor: "#062762",
              color: "#FFFFFF",
              textDecoration: "none !important",
              cursor: "not-allowed",
            },
          }}
          className={style.button}
          onClick={() => {
            handleNavigateToGiftsRoute();
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          <img src={giftIcon} className={style.icon} />
          <Box>
            <span style={{ cursor: "not-allowed" }} className={style.buttonText}>
              {t("gift_cards")}
            </span>
          </Box>
          <img
            className={style.caret}
            src={caret}
            style={{
              position: "absolute",
              top: "50%",
              left: "100%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </Button>
      )}
      {!isTablet && (
        <Button
          sx={{
            ...styles.button,
            textDecoration: "none !important",
            "&:hover": {
              backgroundColor: "#062762",
              color: "#FFFFFF",
              textDecoration: "none !important",
              cursor: "not-allowed",
            },
          }}
          className={style.disabled_button}
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          <img src={cartIcon} className={style.icon} />
          <Box>
            <span style={{ cursor: "not-allowed" }} className={style.disabled_button_text}>
              {t("orders")}
            </span>
          </Box>
          <img
            className={style.caret}
            src={caret}
            style={{
              position: "absolute",
              top: "50%",
              left: "100%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </Button>
      )}

      {!isTablet && (
        <Button
          sx={{
            ...styles.button,
            textDecoration: "none !important",
            "&:hover": {
              backgroundColor: "#062762",
              color: "#FFFFFF !important",
              textDecoration: "none !important",
            },
          }}
          className={style.button}
          onClick={() => {
            handleNavigateToFinantialClosure();
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          <img src={closure} className={style.icon} />
          <Box>
            <span className={style.buttonText}>{t("finantial_closure")}</span>
          </Box>
          <img
            className={style.caret}
            src={caret}
            style={{
              position: "absolute",
              top: "50%",
              left: "100%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </Button>
      )}
    </Box>
  );
};
