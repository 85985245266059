import styled from "styled-components";

interface IWrapperInterface {
  error: boolean;
}

interface ITitle {
  isDE: boolean;
}

interface IPrice {
  isDiscount?: boolean;
  isDE: boolean;
}

export const WrapperWithError = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Wrapper = styled.div<IWrapperInterface>`
  width: 100%;
  max-width: 410px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  border: ${(props) => (props.error ? "1px solid #E31340" : "1px solid #79747e")};
  border-radius: 5px;
  color: #000000;
  background: #ffffff;
`;

export const SubTitleBox = styled.div<IPrice>`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 400px) {
    width: 20%;
  }

  @media (max-width: 380px) {
    width: ${(props) => (props.isDE ? "25%" : "23%")};
  }
`;

export const SubTitle = styled.span<ITitle>`
  white-space: nowrap;
  font-weight: 600;
  color: #000000;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: ${(props) => (props.isDE ? "12px" : "18px")};
  }
`;

export const SubTitleTwo = styled.span`
  display: flex;
  font-size: 14px;
  white-space: nowrap;
  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 390px) {
    font-size: 9px;
  }
`;

export const Price = styled.span<IPrice>`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${(props) => (props.isDiscount ? "#E31340" : "#000000")};
  font-weight: 600;

  @media (max-width: 768px) {
    height: 75%;
    margin-right: 0;
    font-weight: ${(props) => (props.isDE ? "500" : "600")};
  }
`;

export const PriceWrapper = styled.div`
  height: 60px;
  display: flex;
  margin-top: 20px;
  gap: 10px;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const PriceDescription = styled.s`
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: right;
  color: #707070;
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  height: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ErrorMessage = styled.div`
  padding-top: 1px;
  color: #e31340;
  font-size: 14px;
  margin: 0 0 0 10px;
  font-weight: 400;
`;

export const DescriptionMessage = styled.div`
  padding-top: 1px;
  color: #000000;
  font-size: 14px;
  margin: 0 0 0 10px;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;
