import { SlotKey } from "src/core/types/bundles";

export const getNoTicketsText = ({ tourName }: { tourName: SlotKey }) => {
  switch (tourName) {
    case "ossuary":
      return "no_available_timeslots_ossuary";
    default:
      return "no_available_timeslots";
  }
};
